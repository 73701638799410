export default [
  {
    path: '/admin/series',
    name: 'SerieList',
    component: () => import(/* webpackChunkName: "SerieList" */ '../../views/admin/serie/List.vue')
  },
  {
    path: '/admin/series/nova',
    name: 'SerieForm',
    component: () => import(/* webpackChunkName: "SerieForm" */ '../../views/admin/serie/Form.vue')
  },
  {
    path: '/admin/series/:id/editar',
    name: 'SerieFormEdit',
    component: () => import(/* webpackChunkName: "SerieForm" */ '../../views/admin/serie/Form.vue')
  }
]
