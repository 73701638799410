<template>
  <div class="noticia">
    <div v-if="noticia.thumbnail !== undefined" class="thumbnail-wrapper">
      <img class="thumbnail" :src="`${API_URL}/public/news/thumbnails/${noticia.thumbnail}`" alt="" />
    </div>
    <img v-else class="thumbnail" src="https://via.placeholder.com/288x144?text=Thumbnail" />
    <div class="tags">
      <a
        v-for="tag in tags"
        :key="tag"
        class="tag"
        href="#"
      >
        {{ tag }}
      </a>
    </div>
    <h3 class="title">
      <router-link
        :to="`/noticias/${noticia.id}`"
      >
        {{ noticia.title }}
      </router-link>
    </h3>
    <footer class="footer">
      <p class="autor">{{ noticia.author }}</p>
      <p class="data">{{ noticia.createdAt | formatarData }}</p>
    </footer>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { API_URL } from '@/api'

export default {
  name: 'Noticia',
  props: {
    noticia: {
      type: Object,
      required: true
    }
  },
  computed: {
    tags () {
      return this.noticia.tags.split(',')
    },
    API_URL () {
      return API_URL
    }
  },
  filters: {
    formatarData (data) {
      return format(new Date(data), 'MMM dd, yyyy')
    }
  }
}
</script>

<style lang="scss" scoped>
.noticia {
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #C4C4C4;
    margin-bottom: 24px;
  }
  .thumbnail {
    width: 100%;
    margin-bottom: 12px;
  }
  .tags {
    margin-bottom: 12px;
    .tag {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      border-radius: 50px;
      margin: 0;
    }
  }
  .title {
    font-weight: 700;
    color: #000000;
    margin-bottom: 12px;
  }
  .footer {
    padding: 0;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    .autor, .data {
      font-size: 14px;
    }
  }
}
</style>
