import axios from 'axios'
const BASE_URL = 'https://coopenaza-api.herokuapp.com'
// const BASE_URL = 'http://localhost:3000'
const API_URL = BASE_URL

const api = axios.create({
  baseURL: BASE_URL
})

export {
  API_URL,
  api
}

export default api
