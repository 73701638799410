import teacher from './teacher'
import student from './student'
import responsible from './responsible'

const defaults = [
  {
    path: '/p/perfil',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "Profile" */ '../../views/painel/Profile.vue')
  }
]

export default [
  ...defaults,
  ...teacher,
  ...student,
  ...responsible
]
