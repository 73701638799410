import userRules from '../config/userRules'

const userDefault = () => {
  const user = window.localStorage.getItem('user')
  if (user !== null) {
    return JSON.parse(user)
  } else {
    return {
      email: '',
      rule: userRules.VISITANT
    }
  }
}

const state = userDefault()

const actions = {
  login ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      window.localStorage.setItem('user', JSON.stringify(payload))
      commit('LOGIN', payload)
      resolve(state)
    })
  },
  logout ({ commit }) {
    window.localStorage.removeItem('user')
    commit('LOGIN', userDefault())
  }
}

const mutations = {
  LOGIN (state, payload) {
    Object.assign(state, payload)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
