<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>Coopenaza</strong> {{ year }}.
      </p>
    </div>
  </footer>
</template>

<script>
import format from 'date-fns/format'
export default {
  name: 'Footer',
  computed: {
    year () {
      return format(new Date(), 'yyyy')
    }
  }
}
</script>
