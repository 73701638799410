export default [
  {
    path: '/admin/turmas',
    name: 'ClassList',
    component: () => import(/* webpackChunkName: "ClassList" */ '../../views/admin/class/List.vue')
  },
  {
    path: '/admin/turmas/nova',
    name: 'ClassForm',
    component: () => import(/* webpackChunkName: "ClassForm" */ '../../views/admin/class/Form.vue')
  },
  {
    path: '/admin/turmas/:id/editar',
    name: 'ClassFormEdit',
    component: () => import(/* webpackChunkName: "ClassForm" */ '../../views/admin/class/Form.vue')
  }
]
