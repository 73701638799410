<template>
  <section class="banner">
    <carousel
      :perPage="1"
      :paginationEnabled="false"
      autoplay
    >
      <slide v-for="image in images" :key="image.id">
        <img class="banner-image" :src="`${API_URL}/public/banners/${image.src}`" :alt="image.alt" />
      </slide>
    </carousel>
  </section>
</template>

<script>
import { api, API_URL } from '@/api'
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'Banner',
  components: {
    Carousel,
    Slide
  },
  created () {
    api.get('/banners').then(res => {
      this.images = res.data
    })
  },
  data () {
    return {
      images: []
    }
  },
  computed: {
    API_URL () {
      return API_URL
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-image {
  display: block;
  width: 100%;
  height: 500px;
  object-fit: cover;
}
</style>
