export default [
  {
    path: '/p/r',
    redirect: '/p/r/alunos'
  },
  {
    path: '/p/r/alunos',
    name: 'ResponsiblesStudents',
    component: () => import(/* webpackChunkName: "ResponsibleStudents" */ '../../views/painel/responsible/student/List.vue')
  }
]
