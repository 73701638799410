import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import layout from './admin/layout'
import user from './user'

const dataState = createPersistedState({
  paths: ['data']
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    layout,
    user
  },
  plugins: [dataState]
})
