<template>
  <div class="home">
    <navbar />
    <banner />
    <call-to-action to="/login" />
    <section class="noticias section">
      <h3 class="title">Últimas notícias</h3>
      <div class="columns">
        <div v-for="noticia in noticias" :key="noticia.id" class="column is-one-third">
          <noticia :noticia="noticia" />
        </div>
      </div>
    </section>
    <section class="redes section">
      <h3 class="title">Nossas redes sociais</h3>
      <div class="redes-list">
        <a class="rede facebook" href="https://www.instagram.com/coopenaza_oficial/"><i class="lni lni-facebook" /></a>
        <a class="rede instagram" href="https://www.instagram.com/coopenaza_oficial/"><i class="lni lni-instagram" /></a>
      </div>
    </section>
    <section class="feed section">
      <h3 class="title">Últimas Postagem</h3>
      <div v-if="loadingFeed" class="loading">
        <i class="fi-xnsus2-loading" />
      </div>
      <div v-else class="images columns is-desktop is-multiline is-centered">
        <div class="column is-4" v-for="i in feed.slice(0,6)" :key="i.node.id">
           <img class="image-feed" :src="i.node.display_url" :alt="i.node.accessibility_caption">
        </div>
      </div>
    </section>
    <section class="acessos section">
      <h3 class="title">Acesso Rápido</h3>
      <div class="buttons">
        <button class="button is-primary is-fullwidth">Últimas aulas</button>
        <a class="button is-primary is-fullwidth" href="https://app2.activesoft.com.br/sistema/entrar.asp?p=COOPENAZA" target="_blank">Boletim</a>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import axios from 'axios'
import api from '@/api'
import Navbar from '@/layout/Navbar'
import Banner from '@/components/Banner'
import CallToAction from '@/components/CallToAction'
import Noticia from '@/components/Noticia'
import Footer from '@/layout/Footer'

export default {
  name: 'Home',
  components: {
    Navbar,
    Banner,
    CallToAction,
    Noticia,
    Footer
  },
  created () {
    api.get('/news').then(res => {
      this.noticias = res.data
    })
  },
  data () {
    return {
      loadingFeed: true,
      feed: [],
      noticias: []
    }
  },
  mounted () {
    axios.get('https://www.instagram.com/coopenaza_oficial/?__a=1')
      .then(res => {
        if (res.status === 200) {
          this.feed = res.data.graphql.user.edge_owner_to_timeline_media.edges
          this.loadingFeed = false
        }
      })
      .catch(() => {
        this.loadingFeed = false
      })
  }
}
</script>

<style lang="scss" scoped>
.home {
  .redes {
    .redes-list {
      width: 100%;
      display: flex;
      .rede {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        &.facebook {
          color: #FFF;
          background-color: #4267B2;
        }
        &.instagram {
          color: #FFF;
          background: #f09433;
          background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
          background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
          background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
        }
      }
    }
  }
  .feed {
    .loading {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .image-feed {
      display: block;
      width: 100%;
      height: 100%;
      max-width: 360px;
      max-height: 360px;
      object-fit: cover;
      margin: 0 auto;
    }
  }
}
</style>
