export default [
  {
    path: '/admin/turmas/:idClass/atividades/nova',
    name: 'ActivityForm',
    component: () => import(/* webpackChunkName: "ActivityForm" */ '../../views/admin/activity/Form.vue')
  },
  {
    path: '/admin/turmas/:idClass/atividades/:id/editar',
    name: 'ActivityFormEdit',
    component: () => import(/* webpackChunkName: "ActivityFormEdit" */ '../../views/admin/activity/Form.vue')
  }
]
