<template>
  <section class="call-to-action is-primary">
    <div class="cta-body">
      <div class="container has-text-centered">
        <p class="subtitle">
          Acesse nosso portal
        </p>
        <button v-if="internal" class="button is-warning" @click="redirect">Clique aqui</button>
        <a v-else class="button is-warning" :href="to">Clique aqui</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CallToAction',
  props: {
    to: {
      type: String,
      required: true
    },
    internal: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    redirect () {
      this.$router.push(this.to)
    }
  }
}
</script>

<style lang="scss" scoped>
.call-to-action {
  width: 100%;
  height: 104px;
  padding: 14px;
  background-color: #23269E;
  .subtitle {
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 10px;
  }
  .button {
    width: 146px;
    height: 45px;
  }
}
</style>
