export default [
  {
    path: '/admin/alunos',
    name: 'StudentList',
    component: () => import(/* webpackChunkName: "StudentList" */ '../../views/admin/student/List.vue')
  },
  {
    path: '/admin/alunos/novo',
    name: 'StudentForm',
    component: () => import(/* webpackChunkName: "StudentForm" */ '../../views/admin/student/Form.vue')
  },
  {
    path: '/admin/alunos/:id/editar',
    name: 'StudentFormEdit',
    component: () => import(/* webpackChunkName: "StudentForm" */ '../../views/admin/student/Form.vue')
  },
  {
    path: '/admin/alunos/:idAluno/responsaveis/novo',
    name: 'ResponsibleForm',
    component: () => import(/* webpackChunkName: "ResponsibleForm" */ '../../views/admin/responsable/Form.vue')
  },
  {
    path: '/admin/alunos/:idAluno/responsaveis/:id/editar',
    name: 'ResponsibleFormEdit',
    component: () => import(/* webpackChunkName: "ResponsibleForm" */ '../../views/admin/responsable/Form.vue')
  }
]
