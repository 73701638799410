export default [
  {
    path: '/p/s',
    redirect: '/p/s/turmas'
  },
  {
    path: '/p/s/turmas',
    name: 'StudentClasses',
    component: () => import(/* webpackChunkName: "StudentClasses" */ '../../views/painel/student/class/List.vue')
  },
  {
    path: '/p/s/turmas/:id',
    name: 'StudentClass',
    component: () => import(/* webpackChunkName: "StudentClass" */ '../../views/painel/student/class/Class.vue')
  },
  {
    path: '/p/s/atividades/:id',
    name: 'StudentActivity',
    component: () => import(/* webpackChunkName: "StudentActivity" */ '../../views/painel/student/activity/Activity.vue')
  }
]
