import dashboard from './dashboard'
import discipline from './discipline'
import student from './student'
import teacher from './teacher'
import serie from './serie'
import _class from './class'
import activity from './activity'
import banner from './banner'
import news from './news'

export default [
  ...dashboard,
  ...discipline,
  ...student,
  ...teacher,
  ...serie,
  ..._class,
  ...activity,
  ...banner,
  ...news
]
