export default [
  {
    path: '/admin/noticias',
    name: 'NewsList',
    component: () => import(/* webpackChunkName: "NewsList" */ '../../views/admin/news/List.vue')
  },
  {
    path: '/admin/noticias/nova',
    name: 'NewsForm',
    component: () => import(/* webpackChunkName: "NewsForm" */ '../../views/admin/news/Form.vue')
  },
  {
    path: '/admin/noticias/:id/editar',
    name: 'NewsFormEdit',
    component: () => import(/* webpackChunkName: "NewsForm" */ '../../views/admin/news/Form.vue')
  }
]
