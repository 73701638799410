<template>
  <div id="app">
    <router-view/>
  </div>
</template>

// eslint-disable-next-line no-use-before-define
<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
</style>
