export default [
  {
    path: '/admin',
    name: 'Dashboard',
    redirect: '/admin/banners',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../../views/admin/Dashboard.vue')
  },
  {
    path: '/admin/config',
    name: 'Config',
    component: () => import(/* webpackChunkName: "Config" */ '../../views/admin/Config.vue')
  }
]
