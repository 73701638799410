export default [
  {
    path: '/p/t',
    redirect: '/p/t/turmas'
  },
  {
    path: '/p/t/turmas',
    name: 'TeacherClassList',
    component: () => import(/* webpackChunkName: "TeacherClassList" */ '../../views/painel/teacher/class/List.vue')
  },
  {
    path: '/p/t/turmas/:idClass/atividades',
    name: 'TeacherActivityList',
    component: () => import(/* webpackChunkName: "TeacherActivityForm" */ '../../views/painel/teacher/activity/List.vue')
  },
  {
    path: '/p/t/turmas/:idClass/atividades/nova',
    name: 'TeacherActivityForm',
    component: () => import(/* webpackChunkName: "TeacherActivityForm" */ '../../views/painel/teacher/activity/Form.vue')
  },
  {
    path: '/p/t/turmas/:idClass/alunos',
    name: 'TeacherStudentList',
    component: () => import(/* webpackChunkName: "TeacherActivityForm" */ '../../views/painel/teacher/student/List.vue')
  },
  {
    path: '/p/t/turmas/:idClass/alunos/:idStudent/boletim',
    name: 'TeacherReportCard',
    component: () => import(/* webpackChunkName: "TeacherReportCard" */ '../../views/painel/teacher/reportCard/ReportCard')
  }
]
