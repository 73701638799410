import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import userRules from '@/config/userRules'

import Home from '../views/Home.vue'
import adminRoutes from './admin'
import painelRoutes from './painel'

Vue.use(VueRouter)

const BaseRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sobre',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '../views/About.vue')
  },
  {
    path: '/contato',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
  },
  {
    path: '/noticias/:id',
    name: 'Noticia',
    component: () => import(/* webpackChunkName: "New" */ '../views/New.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  }
]

const routes = [
  ...BaseRoutes,
  ...adminRoutes,
  ...painelRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const userRule = store.state.user.rule

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth && userRule === userRules.VISITANT) {
    next('/login')
  }

  return next()
})

export default router
