export default [
  {
    path: '/admin/professores',
    name: 'TeacherList',
    component: () => import(/* webpackChunkName: "TeacherList" */ '../../views/admin/teacher/List.vue')
  },
  {
    path: '/admin/professores/novo',
    name: 'TeacherForm',
    component: () => import(/* webpackChunkName: "TeacherForm" */ '../../views/admin/teacher/Form.vue')
  },
  {
    path: '/admin/professores/:id/editar',
    name: 'TeacherFormEdit',
    component: () => import(/* webpackChunkName: "TeacherForm" */ '../../views/admin/teacher/Form.vue')
  }
]
