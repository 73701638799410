const VISITANT = 'VISITANT'
const ADMIN = 'ADMIN'
const TEACHER = 'TEACHER'
const STUDENT = 'STUDENT'
const RESPONSIBLE = 'RESPONSIBLE'

export default {
  VISITANT,
  ADMIN,
  TEACHER,
  STUDENT,
  RESPONSIBLE
}
