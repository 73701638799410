const layoutDefault = {
  sidebarOpen: true
}

const state = () => ({
  ...layoutDefault
})

const getters = {}

const actions = {
  toogleSidebar ({ commit, state }) {
    commit('SET_SIDEBAR', !state.sidebarOpen)
  }
}

const mutations = {
  SET_SIDEBAR (state, payload) {
    state.sidebarOpen = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
