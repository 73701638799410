export default [
  {
    path: '/admin/disciplinas',
    name: 'DisciplineList',
    component: () => import(/* webpackChunkName: "DisciplineList" */ '../../views/admin/discipline/List.vue')
  },
  {
    path: '/admin/disciplinas/nova',
    name: 'DisciplineForm',
    component: () => import(/* webpackChunkName: "DisciplineForm" */ '../../views/admin/discipline/Form.vue')
  },
  {
    path: '/admin/disciplinas/:id/editar',
    name: 'DisciplineFormEdit',
    component: () => import(/* webpackChunkName: "DisciplineForm" */ '../../views/admin/discipline/Form.vue')
  }
]
